import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import Typography from '@mui/material/Typography';
import { COLORS } from '../constants/constants';

function NEWModalVideoPicture() {
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false); // Nuevo estado para el hover

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ marginTop: { xs: 6, md: 10 }, position: 'relative', display: 'inline-block' }}>
            <img
                src="/NEWimages/modal-image.jpeg"
                alt="Thumbnail"
                onClick={handleClickOpen}
                onMouseEnter={() => setHover(true)} // Manejar el evento de entrada del ratón
                onMouseLeave={() => setHover(false)} // Manejar el evento de salida del ratón
                style={{
                    //cursor: 'pointer',
                    width: '95%',
                    borderRadius: 6,
                    maxWidth: '1200px'
                }}
            />

            {/* <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                padding: hover ? '12px' : '10px',
                borderRadius: '30px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
            }}>
                <PlayCircleRoundedIcon sx={{ marginRight: '8px', color: COLORS.orangeICB }} />
                <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>
                    iCoachBasketball in 30 seconds
                </Typography>
            </Box> */}

            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '9%',
                    maxWidth: '1250px',
                    bgcolor: 'transparent',
                    borderRadius: 2
                }}>

                    <Box id="modal-description" sx={{ mt: 0 }}>
                        <video style={{ borderRadius: 4 }} width="100%" controls>
                            <source src="/NEWvideos/NEWplanPractices.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Box>
            </Modal> */}

        </Box>
    );
}

export default NEWModalVideoPicture;