import React from 'react';
import { Box } from '@mui/material';

const NEWLayout = ({ children }) => {
    return (
        <Box sx={{
            width: '100%',
            minHeight: '100vh',
            backgroundImage: 'url("/NEWimages/page-illustration.svg")',
            // backgroundSize: 'cover',
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'black'
        }}>
            {children}
        </Box>
    );

}

export default NEWLayout;