import * as React from 'react';
import NEWLayout from '../NEWcomponents/NEWlayout';
import NEWOpinionsFromUsers from '../NEWcomponents/NEWOpinionsFromUsers';
import NEWheader from '../NEWcomponents/NEWheader';
import NEWIframeMeeting from '../NEWcomponents/NEWIframeMeeting';
import NEWFooter from '../NEWcomponents/NEWFooter';
import NEWCarousel from '../NEWcomponents/NEWCarousel';

export default function NEWmeetingPage() {
  return (
    <NEWLayout>
      <NEWheader />
      <NEWIframeMeeting />
      <NEWCarousel />
      <NEWOpinionsFromUsers />
      <NEWFooter />
    </NEWLayout>
  );
}
