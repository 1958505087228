import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { COLORS, app, appSignUp } from '../constants/constants';
import { darken, lighten } from '@mui/system';
import NEWLanguageSwitcher from './NEWLanguageSwitcher';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NEWheader() {
    const { t } = useTranslation();
    return (
        <Box sx={{ paddingTop: { xs: 1, md: 2 }, maxWidth: '1250px', margin: 'auto' }}>
            <Box sx={{ width: '95%', margin: 'auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#0c1424', height: { xs: '50px', md: '57px' }, borderRadius: 4, border: '1px solid #2c3444' }}>
                {/* <img src="/NEWimages/icbWhite.svg" alt="Descripción de la imagen" style={{ padding: '10px', width: '35px', height: 'auto' }} /> */}

                <Link to="/">
                    <img src="/images/icbWhite.svg" alt="iCoachBasketball" style={{ padding: '10px', width: '30px', height: 'auto' }} />
                </Link>

                <Box sx={{ display: 'flex', gap: 1, marginRight: { xs: 1, md: 2 }, alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            window.location.href = app;
                        }}
                        sx={{
                            height: '33px',
                            textTransform: 'none',
                            borderRadius: 2,
                            backgroundColor: '#0c1424', // Color de fondo
                            border: '1px solid #2c3444', // Borde
                            '&:hover': {
                                backgroundColor: darken('#2c3444', 0.9), // Color de fondo al pasar el ratón
                            }
                        }}
                    >
                        {t('Header00')}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => {
                            window.location.href = appSignUp;
                        }}
                        sx={{
                            height: '33px',
                            textTransform: 'none',
                            borderRadius: 2,
                            backgroundColor: COLORS.blueICB, // Color de fondo
                            border: `1px solid ${lighten(COLORS.blueICB, 0.1)}`, // Borde
                            '&:hover': {
                                backgroundColor: darken(COLORS.blueICB, 0.1), // Color de fondo al pasar el ratón
                            }
                        }}
                    >
                        {t('Header01')}
                    </Button>
                    <NEWLanguageSwitcher />
                </Box>
            </Box>
        </Box>
    );
}

export default NEWheader;
