import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import "./i18n.js"
import NEWNotFoundPage from './NEWpages/NEWNotFoundPage.js';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import NEWlandingPage from './NEWpages/NEWlandingPage.js';
import NEWmeetingPage from './NEWpages/NEWmeetingPage.js';
import NEWPricingPage from './NEWpages/NEWPricingPage.js';
import NEWsupportPage from './NEWpages/NEWsupportPage.js';
//import ForClubsPage from './pages/ForClubsPage.js';

function App() {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Routes>


            {/* NEW */}
            <Route path="" element={<><Helmet><title>{t('Helmet00')}</title></Helmet><NEWlandingPage /></>} />
            <Route path="/meeting" element={<><Helmet><title>{t('Helmet02')}</title></Helmet><NEWmeetingPage /></>} />
            <Route path="/pricing" element={<><Helmet><title>{t('Helmet01')}</title></Helmet><NEWPricingPage /></>} />
            <Route path="/support" element={<><Helmet><title>{t('Helmet04')}</title></Helmet><NEWsupportPage /></>} />

            <Route path="*" element={<><Helmet><title>{t('Helmet03')}</title></Helmet><NEWNotFoundPage /></>} /> {/* Ruta para manejar páginas no encontradas */}



            {/* OLD */}
            {/* <Route path="" element={<><Helmet><title>{t('Helmet00')}</title></Helmet><LandingPage /></>} />
            <Route path="/pricing" element={<><Helmet><title>{t('Helmet01')}</title></Helmet><PricingPage /></>} />
            <Route path="/meeting" element={<><Helmet><title>{t('Helmet02')}</title></Helmet><HubspotMeetingPage /></>} />
            <Route path="/support" element={<><Helmet><title>{t('Helmet04')}</title></Helmet><SupportPage /></>} /> */}
            {/* <Route path="*" element={<><Helmet><title>{t('Helmet03')}</title></Helmet><NotFoundPage /></>} /> Ruta para manejar páginas no encontradas */}

          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;