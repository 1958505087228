import { useTranslation } from 'react-i18next';
import { Box, lighten } from '@mui/material';
import Apple from '@mui/icons-material/Apple';
import Android from '@mui/icons-material/Android';
import { Typography } from '@mui/material';
import { app } from '../constants/constants';

export default function NEWinstallApp() {
    const { t } = useTranslation();
    return (
        <Box sx={{
            //background: `linear-gradient(to right, #000, ${lighten('#000', 0.1)}, #000)`,
            width: '90%',
            margin: 'auto',
            marginTop: 10,
        }}>

            {/* DIVIDER */}
            <Box sx={{
                height: '1px',
                width: '90%',
                background: `linear-gradient(to right, #000000, ${lighten('#0c1424', 0.5)}, #000000)`,
                margin: 'auto',
            }} />

            <Typography sx={{
                fontFamily: 'var(--font-inter), sans-serif',
                fontSize: { xs: '15px', md: '20px' },
                fontWeight: 600,
                color: 'transparent',
                background: 'linear-gradient(90deg, #99c2e0,#b3d1ec,#cce0f7,#b3d1ec,#99c2e0)',
                backgroundSize: '200% 200%',
                WebkitBackgroundClip: 'text',
                lineHeight: 1.1,
                marginTop: '70px',
            }}>
                {t('BannerApp02')}
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Box
                    sx={{
                        width: '70px',
                        backgroundColor: 'rgba(128, 128, 128, 0.9)',
                        padding: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        cursor: 'pointer',
                        zIndex: 2,
                        '&:hover': {
                            backgroundColor: 'rgba(128, 128, 128, 1)',
                        }
                    }}
                    onClick={() => window.open('https://apps.apple.com/app/icoachbasketball-com/6478594779', '_blank')}
                >
                    <Apple style={{ color: '#fff' }} />
                    <Typography sx={{ fontSize: '10px', color: '#fff', marginTop: 1 }}>App Store</Typography>
                </Box>

                <Box
                    sx={{
                        width: '70px',
                        marginLeft: 1,
                        backgroundColor: 'rgba(128, 128, 128, 0.9)',
                        padding: 2,
                        borderRadius: 2,
                        textAlign: 'center',
                        cursor: 'pointer',
                        zIndex: 2,
                        '&:hover': {
                            backgroundColor: 'rgba(128, 128, 128, 1)',
                        }
                    }}
                    onClick={() => window.open(`${app}installandroid`, '_blank')}
                >
                    <Android style={{ color: '#fff' }} />
                    <Typography sx={{ fontSize: '10px', color: '#fff', marginTop: 1 }}>Android</Typography>
                </Box>
            </Box>
        </Box >

    );
};