import * as React from 'react';
import NEWLayout from '../NEWcomponents/NEWlayout';
import NEWPricingCards from '../NEWcomponents/NEWPricingCards';
import NEWOpinionsFromUsers from '../NEWcomponents/NEWOpinionsFromUsers';
import NEWBannerScheduleMeeting from '../NEWcomponents/NEWBannerScheduleMeeting';
import NEWheader from '../NEWcomponents/NEWheader';
import NEWFooter from '../NEWcomponents/NEWFooter';
export default function NEWPricingPage() {
  return (
    <NEWLayout>
      <NEWheader />
      <NEWPricingCards />
      <NEWOpinionsFromUsers />
      <NEWBannerScheduleMeeting />
      <NEWFooter />
    </NEWLayout>
  );
}