import * as React from 'react';
import NEWheader from '../NEWcomponents/NEWheader';
import NEWLayout from '../NEWcomponents/NEWlayout';
import NEWintro from '../NEWcomponents/NEWintro';
import NEWModalVideoPicture from '../NEWcomponents/NEWModalVideoPicture';
import NEWfeaturePlanPractices from '../NEWcomponents/NEWfeaturePlanPractices';
import NEWFooter from '../NEWcomponents/NEWFooter';
import NEWOpinionsFromUsers from '../NEWcomponents/NEWOpinionsFromUsers';
import NEWCarousel from '../NEWcomponents/NEWCarousel';
import NEWBannerScheduleMeeting from '../NEWcomponents/NEWBannerScheduleMeeting';
import NEWinstallApp from '../NEWcomponents/NEWinstallApp';
export default function LandingPage() {
    return (
        <>
            <NEWLayout>
                <NEWheader />
                <NEWintro />
                <NEWModalVideoPicture />
                <NEWfeaturePlanPractices />
                <NEWinstallApp />
                <NEWCarousel />
                <NEWOpinionsFromUsers />
                <NEWBannerScheduleMeeting />
                <NEWFooter />
            </NEWLayout>
        </>
    );
}
