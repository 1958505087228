import React from 'react';
import { Grid, Typography, Box, Grow, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { lighten } from '@mui/system';

const UserOpinionCard = ({ imgSrc, opinionText, userName }) => (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2,
        maxWidth: '350px',
        margin: 'auto',
        backgroundColor: '#0c1424',
        borderRadius: 4,
        border: `1px solid ${lighten('#0c1424', 0.2)}`,
        height: '100%',
        justifyContent: 'space-between'
    }}>
        <div>
            <img src={imgSrc} alt="Foto" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
            <Typography sx={{ color: '#848dac', paddingTop: 2 }}>
                "{opinionText}"
            </Typography>
        </div>
        <div>
            <Divider sx={{ borderColor: '#848dac', width: '90%', maxWidth: '1000px', margin: 'auto', height: '1px', paddingTop: 2 }} />
            <Typography sx={{ color: '#848dac', paddingTop: 2 }}>
                {userName}
            </Typography>
        </div>
    </Box>
);

export default function NEWOpinionsFromUsers() {
    const { t } = useTranslation();

    return (

        <Box sx={{
            maxWidth: '1100px',
            margin: 'auto',
            backgroundImage: "/NEWimages/page-illustration.svg",
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
        }}>

            {/* DIVIDER */}
            <Box sx={{
                height: '1px',
                width: '90%',
                background: `linear-gradient(to right, #000000, ${lighten('#0c1424', 0.5)}, #000000)`,
                margin: 'auto',
                //marginTop: 10,
            }} />

            <Typography sx={{
                fontFamily: 'var(--font-inter), sans-serif',
                fontSize: { xs: '25px', md: '40px' },
                fontWeight: 600,
                color: 'transparent',
                background: 'linear-gradient(90deg, #99c2e0,#b3d1ec,#cce0f7,#b3d1ec,#99c2e0)',
                backgroundSize: '200% 200%',
                WebkitBackgroundClip: 'text',
                lineHeight: 1.1,
                marginTop: '70px',
            }}>
                {t('OpinionsFromUsers00')}
            </Typography>

            <Typography sx={{
                marginTop: '20px',
                fontFamily: 'var(--font-inter), sans-serif',
                fontSize: '18px',
                color: '#848dac',
            }}>
                {t('OpinionsFromUsers01')}
            </Typography>

            {/* Feature cards */}
            <Grid container spacing={2} justifyContent="center" mt={5} >
                <Grid item xs={12} md={4}>
                    <UserOpinionCard
                        imgSrc="/images/cristina.png"
                        opinionText={t('OpinionsFromUsers02')}
                        userName="Cristina, CB Sant Antoni"
                    />
                </Grid>

                <Grid item xs={12} md={4} sx={{ marginTop: { xs: '40px', md: 0 } }}>
                    <UserOpinionCard
                        imgSrc="/images/william.png"
                        opinionText={t('OpinionsFromUsers03')}
                        userName="William, BK Amager"
                    />
                </Grid>

                <Grid item xs={12} md={4} sx={{ marginTop: { xs: '40px', md: 0 } }}>
                    <UserOpinionCard
                        imgSrc="/images/peptoni.png"
                        opinionText={t('OpinionsFromUsers04')}
                        userName="Pep Toni, CB Muro"
                    />
                </Grid>
            </Grid>


        </Box>


























        // <Grid sx={{ width: '95%', maxWidth: '1000px', margin: 'auto', marginTop: { xs: '20px', md: '70px' }, paddingBottom: 10 }}>
        //     <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
        //         <Grid container justifyContent="center" ref={ref}>
        //             <Typography sx={{
        //                 fontSize: { xs: '25px', md: '40px' },
        //                 color: '#dce4ec',
        //                 fontWeight: '900',
        //                 fontFamily: 'Roboto, sans-serif',
        //                 textAlign: 'center',
        //                 lineHeight: '1'
        //             }}>
        //                 {t('OpinionsFromUsers00')}
        //             </Typography>
        //         </Grid>
        //     </Grow>

        //     <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2500}>
        //         <Grid container justifyContent="center" ref={ref}>
        //             <Typography sx={{
        //                 fontSize: { xs: '20px', md: '25px' },
        //                 marginTop: '20px',
        //                 color: '#98a7b0',
        //                 fontFamily: 'Arial',
        //                 textAlign: 'center',
        //                 lineHeight: '1'
        //             }}>
        //                 {t('OpinionsFromUsers01')}
        //             </Typography>
        //         </Grid>
        //     </Grow>

        // </Grid>
    );
}