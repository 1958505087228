import { useTranslation } from 'react-i18next';
import { Box, lighten, darken } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { COLORS } from '../constants/constants';

export default function NEWBannerScheduleMeeting() {
  const { t } = useTranslation();

  return (
    <Box sx={{
      background: `linear-gradient(to right, #000, ${lighten('#000', 0.2)}, #000)`,
      marginTop: 15,
    }}>

      {/* DIVIDER */}
      {/* <Box sx={{
        height: '1px',
        width: '90%',
        background: `linear-gradient(to right, #000000, ${lighten('#0c1424', 0.5)}, #000000)`,
        margin: 'auto',
      }} /> */}

      <div style={{
        width: '100%',
        overflow: 'hidden',
        paddingBottom: 25,
      }}>


        <Typography sx={{
          fontFamily: 'var(--font-inter), sans-serif',
          fontSize: { xs: '20px', md: '30px' },
          fontWeight: 600,
          color: 'transparent',
          background: 'linear-gradient(90deg, #99c2e0,#b3d1ec,#cce0f7,#b3d1ec,#99c2e0)',
          backgroundSize: '200% 200%',
          WebkitBackgroundClip: 'text',
          lineHeight: 1.1,
          marginTop: '25px',
        }}>
          {t('BannerScheduleHubspot00')}
        </Typography>

        <Typography sx={{
          marginTop: '20px',
          fontFamily: 'var(--font-inter), sans-serif',
          fontSize: '18px',
          color: '#848dac',
        }}>
          {t('BannerScheduleHubspot01')}
        </Typography>

        <Button
          variant="contained"
          onClick={() => {
            window.location.href = '/meeting';
          }}
          sx={{
            height: '33px',
            textTransform: 'none',
            borderRadius: 2,
            marginTop: '20px',
            backgroundColor: COLORS.orangeICB,
            border: `1px solid ${lighten(COLORS.orangeICB, 0.1)}`,
            '&:hover': {
              backgroundColor: darken(COLORS.orangeICB, 0.1),
            }
          }}
        >
          {t('BannerScheduleMeeting00')}
        </Button>

      </div>
    </Box>

  );
};