import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { Language } from '@mui/icons-material';
import { COLORS } from '../constants/constants';

export default function NEWLanguageSwitcher() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setAnchorEl(null);
    window.location.reload();
  };

  return (
    <div>
      {/* <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Language style={{ color: COLORS.whiteICB }} />
      </Button> */}


      <Language
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: COLORS.whiteICB, cursor: 'pointer', fontSize: { xs: '20px', md: '25px' } }}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => changeLanguage('en')}>
          English
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('es')}>
          Español
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('ca')}>
          Català
        </MenuItem>
      </Menu>
    </div>
  );
}